/* General style */
h1 { font-size: 3.8rem; line-height: 5rem}
h2 { font-size: 3.1rem; line-height: 3.5rem }
h3 { font-size: 2.5rem; letter-spacing: -.08rem; line-height: 3.3rem }
h4 { font-size: 1.8rem; letter-spacing: -.05rem; line-height: 2.3rem }
h5 { font-size: 1.6rem; letter-spacing: 0; line-height: 1.6rem }
h6 { font-size: 1.4rem; letter-spacing: 0; line-height: 1.4rem }

.text-small {
  font-size: 16px;
}

.underline {
  text-decoration: underline;
}

.container {
  margin: 0 auto;
  max-width: 80.0rem;
  padding: 0 2.0rem;
  position: relative;
  width: 100%
}

select {
  width: auto;
}

/* Alerts and form errors */
.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.alert p {
  margin-bottom: 0;
}

.alert:empty {
  display: none;
}

.help-block {
  color: #a94442;
  display: block;
  margin: -1rem 0 2rem;
}

/* Hero and logo */
.hero {
  text-align: center;
  border-bottom: 1px solid #e3e3e3;
  background: #eee;
  border-radius: 6px;
  padding: 3em;
  margin-bottom: 3rem;
  font-weight: 200;
  font-size: 120%;
}

.hero p {
  margin: 0;
}

.logo {
  display: block;
}

.logo img {
  height: 60px;
  width: 60px;
  display: block;
  box-shadow: 0 1px 6px rgba(57,73,76,0.25);
}

/* Headers */
header {
  width: 100%;
  background: #fdfdfd;
  border-bottom: 1px solid #eaeaea;
  -moz-box-shadow:0 5px 5px rgba(57,73,76,0.05);
  -webkit-box-shadow: 0 5px 5px rgba(57,73,76,0.05);
  box-shadow: 0 5px 5px rgba(57,73,76,0.05);
  margin-bottom: 2.5rem;
}

header section {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav li {
  display: inline;
}

nav .light {
  padding: 0 5px;
}

header section {
  flex-direction: row;
}

.logo {
  margin: 1.5rem 0;
}

/* Footers */
footer {
  width: 100%;
  background: #fdfdfd;
  margin-top: 2.5rem;
}

footer .drop-shadow {
  height: 5px;
  border-bottom: 1px solid #eaeaea;
  -moz-box-shadow:0 5px 5px rgba(57,73,76,0.05);
  -webkit-box-shadow: 0 5px 5px rgba(57,73,76,0.05);
  box-shadow: 0 5px 5px rgba(57,73,76,0.05);
}

.footer-text {
  padding-top: 3.5rem;
  padding-bottom: 1rem;
}

.footer-links {
  margin-bottom: 2.5rem;
}

.footer-links li {
  list-style-type: none;
  margin-bottom: 0;
}

/* Forms */
textarea {
  min-height: 130px;
}

/* Sticky footer */
html, body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

footer {
  flex-shrink: 0;
}

/* Buttons */
.button, button[type="submit"] {
  font-size: 1.2rem;
  margin-bottom: 0;
}

.button {
  padding: 0 1.5rem;
}

.button-red {
  background-color: #db4848;
  border-color: #db4848;
}

.button-square {
  background-color: var(--link-color);
  border: 0;
  border-radius: 0;
  box-shadow: 0 1px 6px rgba(57,73,76,0.25);
}

.button-square:hover {
  background-color: var(--theme-dark);
}

.button-red.button-clear,
.button-red.button-outline {
  background-color: transparent;
  color: #db4848;
}

.button-red.button-clear {
  border-color: transparent;
}

button.link {
  background: none;
  border: none;
  padding: 0;
  text-transform: none;
  color: var(--link-color);
  cursor: pointer;
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 1em;
  letter-spacing: normal;
  font-weight: 300;
  line-height: 1.6;
  height: auto;
}

button.link:hover {
  background: none;
  color: #606c76;
}

button.light {
  color: #878e95;
}

/* Headings */
h1 .small {
  font-size: 0.7em;
  font-weight: 300;
  font-family: 'Helvetica', 'Arial', sans-serif;
}

/* Fade in/out */
.fadeout {
  transition: opacity 500ms;
}

.fadeout-slow {
	opacity: 0;
  transition: opacity 2000ms;
}

.fadein {
	opacity: 1;
	transition: opacity 500ms;
}

.opacity-zero {
	opacity: 0;
}

/* Sticky elements */
#back-to-top {
  position: fixed;
  width: 64px;
  height: 64px;
  right: 18px;
  bottom: 18px;
  cursor: pointer;
  z-index: 1;
}

@media (max-width: 900px) {
  #post-body #back-to-top {
    width: 42px;
    height: 42px;
  }
}

/* Sessions */
#oauth {
  margin-top: 20px;
}

/* Recent posts */
#recent-posts ul {
  list-style: none;
}

/* Images */
img.small {
  width: 200px;
  height: 200px;
  box-shadow: 0 1px 6px rgba(57,73,76,0.25);
}

img.left {
  float: left;
  margin-top: 8px;
  margin-right: 20px;
}

.is-circle {
  border-radius: 50%;
}

.hidden {
  display: none;
}
