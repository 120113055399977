code {
	background: #f1efee;
	border-radius: 0;
	font-size: 110%;
	margin: 0 .2rem;
	padding: .2rem .5rem;
	color: #444;
	overflow-wrap: break-word;
}

pre {
	background: #f1efee;
	padding: 1.5rem;
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;

	code {
		display: inline-block;
		white-space: pre;
	}

	.k, .na, .nt, .pi, .s2, .sx, .cp {
		color: var(--theme-light);
	}

  .ss {
    color: var(--theme-dark);
  }

	.c, .c1 {
		color: #75716f;
	}
}

.language-bash {
	pre {
		.s2, .se, .si, .nv {
			color: var(--theme-dark);
		}

		.nb {
			color: var(--theme-light);
		}
	}
}
