.no-margin {
  margin: 0;
}

.mt-tiny {
  margin-top: 5px;
}

.mt-small {
  margin-top: 15px;
}

.mr-tiny {
  margin-right: 5px;
}

.mb-tiny {
  margin-bottom: 5px;
}

.ml-tiny {
  margin-left: 5px;
}

.mt-xlarge {
  margin-top: 65px;
}

.my-large {
  margin: 45px 0;
}
